import React from 'react';
import {Button, Typography} from '@mui/material';

export default function Welcome() {
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'linear-gradient(to bottom right, #448AFF, #2979FF)',
            }}
        >
            <Typography variant="h4" component="h1" color="white" gutterBottom>
                Hey there!
            </Typography>
            <img
                src="wip.png"
                alt="Work in Progress"
                style={{width: '300px', marginBottom: '2rem'}}
            />
            <Typography variant="body1" component="p" color="white" gutterBottom>
                Check out my GitHub or LinkedIn profiles!
            </Typography>
            <div>
                <Button
                    variant="contained"
                    color="success"
                    href="https://github.com/istvan-olah"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{marginRight: '1rem'}}
                >
                    GitHub
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    href="https://www.linkedin.com/in/istv%C3%A1n-ol%C3%A1h-b962b81a2/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{marginRight: '1rem'}}
                >
                    LinkedIn
                </Button>
            </div>
            <Typography variant="body1" component="p" color="white" gutterBottom style={{marginTop: '2rem'}}>
                Connect with me on Email or via Discord!
            </Typography>
            <div>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                        window.location.href = 'mailto:1.olah.istvan.75@gmail.com';
                    }}
                    style={{marginRight: '1rem'}}
                >
                    Email
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    href="https://discordapp.com/users/TheNoobBot"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{marginRight: '1rem'}}
                >
                    Discord
                </Button>
            </div>
        </div>
    );
};
